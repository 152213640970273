//const {nb} = await import(o365.getLibUrl('o365.locales.nb.js'))
import utilsDate from 'o365.modules.utils.date.js';
import { useInjectDataObject, useProvideDataObject } from 'o365.vue.composables.DataObject.js';
import ODatePicker from 'o365.vue.components.DatePicker.vue';
import { h, ref, computed, toRef } from 'vue';

const OTextEditor = {
    name: 'OTextEditor',
    props: ['modelValue', 'column'],
    emits: ['update:modelValue'],
    methods: {
        activateEditor() {
            this.$el.focus();
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    template: `
    <input type="text" v-model="internalValue">
    `
};

const ONumberEditor = {
    name: 'ONumberEditor',
    props: ['modelValue', 'column'],
    emits: ['update:modelValue'],
    methods: {
        activateEditor() {
            this.$el.focus();
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    template: `
    <input  type="number" v-model="internalValue">
    `
};

const OBitEditor = {
    name: "OBitEditor",
    props: ['modelValue', 'column'],
    emits: ['update:modelValue'],
    methods: {
        activateEditor() {
            this.$el.focus();
        },
    },
    computed: {
        internalValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    template: `
    <input type="checkbox" v-model="internalValue">
    `,
};

const ODateEditor = {
    name: "ODateEditor",
    props: ['modelValue', 'column'],
    emits: ['update:modelValue'],
    components: { ODatePicker },
    setup(props, context) {
        const inputRef = ref(null);

        const thisDate = computed({
            get() { return props.modelValue; },
            set(pValue) { context.emit('update:modelValue', pValue); }
        });

        function activateEditor() {
            inputRef.value.datepickerRef.closeMenu();
            inputRef.value.datepickerRef.openMenu();
        };

        return { thisDate, inputRef, activateEditor };
    },
    template: `
        <o-date-picker v-model="thisDate" :column="column" ref="inputRef" :format="column.format" :timepicker="column.type === 'datetime'"></o-date-picker>
    `,
};

const OInputEditor = {
    name: 'OInputEditor',
    props: ['modelValue', 'column', 'field', 'dataObject'],
    emits: ['update:modelValue'],
    methods: {
        activateEditor() {
            this.$refs.editorRef?.activateEditor?.call();
        }
    },
    setup(props, context) {
        function getEditor(type) {
            switch (type) {
                case "number":
                    return ONumberEditor;
                case "date":
                case "datetime":
                    return ODateEditor;
                case "bit":
                case "boolean":
                    return OBitEditor;
                default:
                    return OTextEditor;
            }
        };

        if (props.modelValue && props.column) {
            const editor = computed(() => {
                return getEditor(props.column.type);
            });

            return () => h(editor.value, {
                ref: 'editorRef',
                modelValue: props.modelValue,
                column: props.column,
                'onUpdate:modelValue': (value) => { context.emit('update:modelValue', value) }
            });
        } else {
            const { dataObject } = useInjectDataObject({ dataObject: props.dataObject });
            const column = props.column ?? dataObject.fields.getFields(props.field);
            const modelValue = props.modelValue
                ? toRef(props, 'modelValue')
                : computed(() => dataObject.current);
            const editor = computed(() => {
                return getEditor(column.type);
            });

            return () => {
                return h(editor.value, {
                    ref: 'editorRef',
                    modelValue: modelValue.value,
                    column: column,
                    'onUpdate:modelValue': (value) => { context.emit('update:modelValue', value) }
                });
            }
        }
    },
};


export { OTextEditor, ONumberEditor, OBitEditor, ODateEditor, OInputEditor }

/*
<template v-if="editModeOn">
            <input v-focus v-date-picker  v-model="thisDate" @update:modelValue="setDate">
        </template>
        <template v-if="!editModeOn">
            {{formatDate(modelValue[column.field])}}
        </template>
*/
// <date-picker :modelValue="thisDate" v-focus @update:modelValue="setDate" autoApply hideInputIcon></date-picker>
// <input  v-model="modelValue[column.field]">
//<input v-model="thisDate" v-date-picker="thisDate" :column="column">
// <date-picker :modelValue="thisDate" @update:modelValue="setDate" autoApply hideInputIcon></date-picker> 
